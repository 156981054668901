import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { farmsService } from "../../../modules/farms";
import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { ICropAggregationDto } from "../shared/dtos/crop-aggregation.dto";
import { IMapControlsState } from "../shared/interfaces/map-controls-state";
import { MAP_CONTROLS_MODULE_NAME } from "./constants";

const initialState: IMapControlsState = {
  isLoading: false,
  list: [],
  selected: [],
  opened: [],
  selectedFarmLands: [],
  searchFarmLandName: "",
  highlightedFarmLandId: null,
};

export const mapControlsSlice = createSlice({
  name: MAP_CONTROLS_MODULE_NAME,
  initialState,
  reducers: {
    setHighlightedFarmLandId(state, action: PayloadAction<string | null>): void {
      state.highlightedFarmLandId = action.payload;
    },
    setIsLoadingAction(state, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    setListAction(state, action: PayloadAction<ICropAggregationDto[]>): void {
      state.list = action.payload;
      state.selected = [];
      state.opened = [];
      state.selectedFarmLands = [];
    },
    toggleSelectedAction(state, action: PayloadAction<string>): void {
      const value = action.payload;
      const index = state.selected.indexOf(value);
      if (index !== -1) {
        state.selected.splice(index, 1);
      } else {
        state.selected.push(value);
      }
    },
    toggleAllSelectedAction(state, _: PayloadAction): void {
      if (state.list.length === state.selected.length) {
        state.selected.length = 0;
      } else {
        state.selected = state.list.map((item) => item.cropTypeId);
      }
    },
    setOpenedAction(state, action: PayloadAction<string[]>): void {
      state.opened = action.payload;
    },
    toggleOpenedAction(state, action: PayloadAction<string>): void {
      const value = action.payload;
      const index = state.opened.indexOf(value);
      if (index !== -1) {
        state.opened = [];
      } else {
        state.opened = [value];
      }
      // если схлопнули культуру или открыли другую (не важно) - убираем фокус с поля
      state.highlightedFarmLandId = null;
    },
    toggleSelectedFarmLandsAction(state, action: PayloadAction<string>): void {
      const value = action.payload;
      const index = state.selectedFarmLands.indexOf(value);
      if (index !== -1) {
        state.selectedFarmLands.splice(index, 1);
      } else {
        state.selectedFarmLands.push(value);
      }
    },
    setSearchFarmLandNameAction(state, action: PayloadAction<string>): void {
      state.searchFarmLandName = action.payload;
    },
  },
  extraReducers(builder): void {
    builder
      .addCase(fetchCropAggregationAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchCropAggregationAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCropAggregationAction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const mapControlsReducer = mapControlsSlice.reducer;
export const {
  setHighlightedFarmLandId,
  setListAction,
  setIsLoadingAction,
  toggleSelectedAction,
  toggleAllSelectedAction,
  toggleOpenedAction,
  toggleSelectedFarmLandsAction,
  setOpenedAction,
  setSearchFarmLandNameAction,
} = mapControlsSlice.actions;

export const fetchCropAggregationAction = createAsyncThunk<
  void,
  { farmId: string; seasonName: string; filter: ILoopbackFilter }
>(
  `${MAP_CONTROLS_MODULE_NAME}/fetchCropAggregationAction`,
  async ({ farmId, seasonName, filter }, { dispatch }) => {
    const dtos = await farmsService.getCropAggregation(farmId, seasonName, filter);
    dispatch(setListAction(dtos));
  }
);
