import { format } from "date-fns";

import { ISO8601_DATE_FORMAT } from "@constants";
import {
  ILoopbackFilter,
  Operators,
} from "../../../../modules/filter/shared/interfaces/loopback";
import { IPaginationState } from "../../../../modules/filter/shared/interfaces/pagination-types";
import { loopBackFilterAdapter } from "../../../../modules/filter/shared/utils/loopback-filter-adapter";
import { TObservationsFilter } from "../../shared/interfaces/observations-state";
import { OrderGroup } from "../../../../shared/interface";

export type TObservationsRequest = {
  paginationState: IPaginationState;
  filtersState: TObservationsFilter;
  seasonId: string; // upper (global) filter
  farmId: string; // upper (global) filter
  skipRequest: boolean;
  onlyOriginal?: boolean;
};

export type LoopbackObservationsFilter = {
  limit: number;
  offset: number;
  order: OrderGroup;
  seasonId: string | null;
  farmId: string | null;
  observationDate?: string;
  cropTypeId?: string;
  farmLandId?: string;
  phenoPhaseId?: string;
};

export function getObservationsListFilter(
  request: TObservationsRequest
): ILoopbackFilter<LoopbackObservationsFilter> {
  const { limit, offset, order } = request.paginationState;
  const { from, to, cropType, farmLandId, phenoPhaseId } = request.filtersState;
  const { seasonId, farmId } = request;

  const backendFilter = loopBackFilterAdapter<LoopbackObservationsFilter>(
    {
      limit,
      offset,
      order,
      seasonId,
      farmId,
      ...(from !== undefined && { observationDate: format(from, ISO8601_DATE_FORMAT) }),
      ...(cropType !== undefined && { cropTypeId: cropType.id }),
      ...(farmLandId !== undefined && { farmLandId }),
      ...(phenoPhaseId !== undefined && { phenoPhaseId }),
    },
    {
      where: {
        and: [
          { seasonId: "eq" },
          { farmId: "eq" },
          { observationDate: "gte" },
          { observationDate: to ? ["lte", format(to, ISO8601_DATE_FORMAT)] : undefined },
          ...(cropType?.id ? [{ cropTypeId: "eq" as Operators }] : []),
          ...(farmLandId ? [{ farmLandId: "eq" as Operators }] : []),
          ...(phenoPhaseId ? [{ phenoPhaseId: "eq" as Operators }] : []),
        ],
      },
      // optional: ["cropTypeId", "farmLandId", "phenoPhaseId"],
    }
  );
  return backendFilter;
}
